.ProgressBarComponent {
  .ant-steps {
    &-item-title {
      cursor: pointer;
      font-weight: 700;

      &::after {
        background-color: var(--border);
      }
    }

    &-item {
      &:hover .ant-steps-icon {
        color: white !important;
      }

      &-container {
        display: flex;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-steps-icon {
          color: var(--white) !important;
          line-height: 20px;
        }
      }

      &-disabled {
        opacity: 0.3;

        .ant-steps-icon,
        .ant-steps-item-title {
          cursor: not-allowed !important;
        }
      }
    }

    &-item-finish .ant-steps-item-icon {
      background: var(--positive);
      border-color: var(--positive);
    }
    &-item-active .ant-steps-item-icon {
      background: var(--brand_primary);
      border-color: var(--brand_primary);
    }

    &-item-wait .ant-steps-item-icon {
      background: var(--gray_9);
      border-color: var(--gray_9);
    }
  }
}

.AudienceStep {
  .segment-card:hover {
    transition: 0.2s;
    .kariba-icon {
      transition: 0.2s;
      fill: var(--brand_primary);
    }
  }

  .TableComponent {
    .ant-table {
      .selected-row {
        background: rgba(230, 247, 255, 1);
      }
    }
  }
}

.OverviewStep {
  .placement-card {
    display: flex;
    background: var(--white);
    width: 368px;
    // height: 120px;
    padding: var(--sp_lv_4);
    border-radius: var(--br_DEFAULT);
    cursor: pointer;
    border: 1px solid var(--white);
    transition: 0.2s;

    &:hover {
      box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.05);
    }

    &.selected {
      color: var(--brand_primary);
      border-color: var(--brand_primary);
    }

    &__title {
      font-weight: var(--fw_semibold);
      font-size: var(--fs_base);
      margin-bottom: var(--sp_lv_3);
    }
  }
}

.ScheduleStep {
  .ant-input,
  .ant-input-number,
  .ant-select,
  .ant-picker {
    border: 1px solid var(--border) !important;
  }

  .toggle {
    font-weight: var(--fw_semibold);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: var(--sp_lv_2) var(--sp_lv_4);
    transition: 0.2s;
    border: 1px solid var(--border);

    &:nth-child(1) {
      border-radius: var(--br_DEFAULT) 0 0 var(--br_DEFAULT);
    }

    &:nth-child(2) {
      border-radius: 0 var(--br_DEFAULT) var(--br_DEFAULT) 0;
    }

    &-selected {
      font-weight: 600;
      color: var(--brand_primary);
      border-color: var(--brand_primary);
      background: var(--brand_light);
    }
  }

  .section-wrapper {
    background-color: var(--white);
    padding: var(--sp_lv_6) var(--sp_lv_8);
    border-radius: var(--br_rounded);
    margin-bottom: var(--sp_lv_4);

    .picker {
      margin-top: var(--sp_lv_4);
      display: flex;
      gap: var(--sp_lv_4);

      .ant-picker {
        min-width: 100px;
        width: 200px;
      }
    }
  }

  .day-week-wrapper {
    display: flex;
    gap: var(--sp_lv_2);

    .day-week {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--disabled_text);
      background-color: var(--border);
      font-weight: var(--fw_semibold);
      font-size: var(--fs_sm);
      border-radius: var(--br_DEFAULT);
      transition: 0.2s;
      cursor: pointer;

      &-selected {
        color: var(--white);
        background-color: var(--brand_primary);
      }
    }
  }
}

.audience-step-modal {
  padding-bottom: var(--sp_lv_6) !important;
  .ant-modal-body {
    background-color: var(--background_opaque);
    padding: 0;
    min-height: 500px;
  }
}
